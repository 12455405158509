import {
  LOGIN_INIT_START,
  LOGIN_INIT_SUCCESS,
  LOGIN_INIT_FAILURE,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST_SUCCESS,
  RESET_PASSWORD_REQUEST_FAILURE,
  FINISH_INSTALLATION_STARTED,
  FINISH_INSTALLATION_SUCCESS,
  FINISH_INSTALLATION_FAILURE,
  LOGIN_MFA_START,
  LOGIN_MFA_SUCCESS,
  LOGIN_MFA_FAILURE,
  VALIDATE_RESET_PASSWORD_TOKEN,
  VALIDATE_RESET_PASSWORD_TOKEN_FAILURE,
  VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS,
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  START_REFRESH_TOKEN_REQUEST,
  FINISH_REFRESH_TOKEN_REQUEST,
  GET_INVOICING_COMPANIES_SUCCESS,
  GET_INVOICING_COMPANIES,
  GET_INVOICING_COMPANIES_FAILURE,
  LOGOUT_START,
  LOGOUT,
} from '../types';

export const authenticationDefaultState = {
  isAuthenticated: false,
  error: '',
  loading: false,
  token: '',
  companies: [],
  invoicingCompanies: [],
  invoicingCompaniesLoading: false,
  user: {},
  resetPassword: {
    user: {},
    loading: false,
    error: '',
  },
  qr: {},
  verifyToken: '',
  remember: false,
  mfaConfigureRequired: true,
  disabledMFA: false,
  refreshToken: '',
  tokenExpire: null,
  isRequestTokenLoading: false,
};

// eslint-disable-next-line default-param-last
const reducer = (state = authenticationDefaultState, action) => {
  switch (action.type) {
    case START_REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        isRequestTokenLoading: true,
      };
    case FINISH_REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        isRequestTokenLoading: false,
      };
    case LOGIN_INIT_START:
    case LOGIN_MFA_START:
    case LOGIN:
      return {
        ...state,
        isAuthenticated: false,
        error: '',
        loading: true,
        user: {},
      };
    case LOGIN_INIT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        error: '',
        loading: false,
        user: {},
        verifyToken: action.payload.verifyToken,
        companies: action.payload.companies,
        remember: action.payload.remember,
        mfaConfigureRequired: action.payload.mfaConfigureRequired,
        disabledMFA: action.payload.disabledMFA,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        user: action.payload.user,
        error: '',
        loading: false,
        remember: false,
        mfaConfigureRequired: true,
        refreshToken: action.payload.refreshToken,
        tokenExpire: action.payload.tokenExpire,
      };
    case GET_INVOICING_COMPANIES_SUCCESS:
      return {
        ...state,
        invoicingCompanies: action.payload,
        invoicingCompaniesLoading: false,
      };
    case GET_INVOICING_COMPANIES:
      return {
        ...state,
        invoicingCompaniesLoading: true,
      };
    case GET_INVOICING_COMPANIES_FAILURE:
      return {
        ...state,
        invoicingCompaniesLoading: false,
      };
    case LOGIN_MFA_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        error: '',
        loading: false,
        verifyToken: '',
        mfaConfigureRequired: true,
      };
    case LOGIN_FAILURE:
    case LOGIN_INIT_FAILURE:
    case LOGIN_MFA_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        error: action.payload.error.message,
        loading: false,
        verifyToken: '',
        user: {},
      };
    case RESET_PASSWORD:
    case RESET_PASSWORD_REQUEST:
    case VALIDATE_RESET_PASSWORD_TOKEN:
    case SIGNUP:
      return { ...state, resetPassword: { ...state.resetPassword, error: '', user: {}, loading: true } };

    case RESET_PASSWORD_SUCCESS:
    case RESET_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          error: '',
          loading: false,
        },
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        qr: action.payload.qr,
        resetPassword: {
          error: '',
          loading: false,
        },
      };

    case VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          user: action.payload.user,
          error: '',
          loading: false,
        },
      };

    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        qr: {},
        resetPassword: {
          ...state.resetPassword,
          error: action.payload.error.message,
          loading: false,
        },
      };
    case RESET_PASSWORD_REQUEST_FAILURE:
    case VALIDATE_RESET_PASSWORD_TOKEN_FAILURE:
    case SIGNUP_FAILURE:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          error: action.payload.error.message,
          loading: false,
        },
      };
    case FINISH_INSTALLATION_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FINISH_INSTALLATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        verifyToken: null,
        qr: {},
      };
    case FINISH_INSTALLATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error.message,
      };
    case LOGOUT_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LOGOUT:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    default:
      return state;
  }
};

export default reducer;
